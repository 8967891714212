import { Alert } from "antd";

const ErrorPage = () => {
    return (
        <div>
            <Alert 
                message="Zugriff verweigert" 
                description="Bitte den NFC-Chip auf dem Tisch einscannen."
                type='error'
                showIcon
                className='error-alert'
            />
        </div>
    );
};

export default ErrorPage;