import React from 'react';

import { Typography, Layout, FloatButton, Spin } from 'antd';
import { BarsOutlined, SettingOutlined, AppstoreOutlined, PlayCircleOutlined, StarOutlined, TrademarkOutlined } from '@ant-design/icons';
import '../../css/General.scss';

import Tables from './Tables';
import Settings from './Settings';
import Player from './Player';
import Events from './Events';
import Helper from '../../classes/Helper';
import { EventViewModel } from '../../class/viewModel/SystemViewClass';
import { EventClass } from '../../class/SettingsClass';

const { Text } = Typography;
const { Header, Content, Footer, Sider } = Layout;

interface State {
    events: Array<EventClass>,
    activeEvent: EventClass | null,
    setSelectedMenuItem: string,
    menuOpen: boolean,
    isLoading: boolean
}

export default class Admin extends React.PureComponent<any, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            events: [],
            activeEvent: null,
            setSelectedMenuItem: "3",
            menuOpen: false,
            isLoading: true
        };
    }    

    componentDidMount() {
        this.getData();
    }
    
    private getData = (): void => { 
        fetch('api/settings/GetEvent', { 
            headers: { 'Content-type': 'application/json' }, 
            method: 'GET', 
        }) 
            .then(response => response.json()) 
            .then((data: EventViewModel) => { 
                const events = Helper.ConvertData(data.events, EventClass) as Array<EventClass>; 
                const activeEvent = events.find(event => event.active) || null; 
                this.setState({ events, activeEvent, isLoading: false }); 
            }) 
            .catch(error => { 
                console.error('Fetch error:', error); 
                this.setState({ events: [], isLoading: false }); 
            }); 
    };

    private getActiveEventStyle = (event: EventClass): string => { 
        if (!event.active) return ''; 
        switch(event.name) { 
            case 'Silvester': return 'Silvester'; 
            case 'Frühling': return 'Frühling'; 
            case 'Ostern': return 'Ostern';
            case 'Sommer': return 'Sommer';
            case 'Herbst': return 'Herbst';
            case 'Halloween': return 'Halloween';
            case 'Winter': return 'Winter';
            case 'Weihnachten': return 'Weihnachten';
            default: return '';
        }
    };
    
    private getActiveEventStyleIconOne = (event: EventClass): React.ReactNode => { 
        if (!event.active) return ''; 
        switch(event.name) { 
            case 'Silvester': return '🎆'; 
            case 'Frühling': return '🌼'; 
            case 'Ostern': return '🐇';
            case 'Sommer': return '⛱️';
            case 'Herbst': return '🍁';
            case 'Halloween': return '🎃';
            case 'Winter': return '☃️';
            case 'Weihnachten': return '🎁';
            default: return '';
        }
    };

    private getActiveEventStyleIconTwo = (event: EventClass): React.ReactNode => { 
        if (!event.active) return ''; 
        switch(event.name) { 
            case 'Silvester': return '🎇'; 
            case 'Frühling': return '🌷'; 
            case 'Ostern': return '🥕';
            case 'Sommer': return '🌞';
            case 'Herbst': return '🍂';
            case 'Halloween': return '🎃';
            case 'Winter': return '❄️';
            case 'Weihnachten': return '🎄';
            default: return '';
        }
    };

    private handleEventUpdate = (events: Array<EventClass>, activeEvent: EventClass | null) => {
        this.setState({ events, activeEvent });
    }

    public render() {
        return (
            <>
            {this.state.isLoading ? <Spin size='large' fullscreen/> : <></>}
            <div className='container'>
                <Header className="header">

                    {this.state.activeEvent ? (
                        <Text className={this.getActiveEventStyle(this.state.activeEvent)}>
                            {this.getActiveEventStyleIconOne(this.state.activeEvent)} Mary's {this.getActiveEventStyleIconTwo(this.state.activeEvent)}
                        </Text>
                    ) : 
                        <Text className="header-text-default">Mary's</Text>
                    }

                    <div onMouseLeave={() => {this.setState({ menuOpen: false })}} className='header-settings-menu'>
                        <FloatButton.Group 
                            trigger="click" 
                            placement={'left'} 
                            icon={<BarsOutlined />} 
                            className='header-settings'
                            open={this.state.menuOpen}
                            onClick={() => this.setState({ menuOpen: true })}
                        >
                            <FloatButton icon={<PlayCircleOutlined />} onClick={() => this.setState({ setSelectedMenuItem: '3' })}/>
                            <FloatButton icon={<StarOutlined />} onClick={() => this.setState({ setSelectedMenuItem: '4' })}/>
                            <FloatButton icon={<AppstoreOutlined />} onClick={() => this.setState({ setSelectedMenuItem: '1' })}/>
                            <FloatButton icon={<SettingOutlined />} onClick={() => this.setState({ setSelectedMenuItem: '2' })}/>
                        </FloatButton.Group>
                    </div>
                </Header>

                <Sider className="sider">
                </Sider>

                <Content className="content">
                    <div className="content-inner">{this.ComponentSwitch(this.state.setSelectedMenuItem)}</div>
                </Content>

                <Footer className="footer">
                    <Text type='secondary' className='footerText'>Made by: info@Feisser.de - Edgar Rinas</Text>
                </Footer>
            </div>
            </>
        );
    }

    private ComponentSwitch(key: string): React.ReactElement {
        switch (key) {
            case '1':
                return <Tables />;
            case '2':
                return <Settings />;
            case '3':
                return <Player />;
            case '4':
                return <Events onEventUpdate={this.handleEventUpdate} />;
        }
    }
}