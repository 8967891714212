import * as React from 'react';

import { Table, Spin, Switch } from 'antd';
import { EventViewModel } from '../../class/viewModel/SystemViewClass';
import { EventClass } from '../../class/SettingsClass';
import Helper from '../../classes/Helper';
import EmptyData from '../../Resources/Empty.png';

interface Props {
    onEventUpdate: (events: Array<EventClass>, activeEvent: EventClass | null) => void;
}

interface State {
    isLoading: boolean,
    event: EventClass,
    events: Array<EventClass>,
    isActive: boolean
}

export default class Events extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            isLoading: true,
            event: new EventClass(),
            events: [],
            isActive: false
        }
    }

    componentDidMount() {
        this.getData();
    }

    public render() {
        return (
            <>
                {this.state.isLoading ? <Spin size='large' fullscreen/> : <></>}
                <this.EventsConstruct/>
            </>
        );
    }

    private getData = (): void => {
        fetch('api/settings/GetEvent', {
            headers: {'Content-type': 'application/json'},
            method: 'GET',
        })
            .then(response => response.json())
            .then((data: EventViewModel) => { 
                const events = Helper.ConvertData(data.events, EventClass) as Array<EventClass>; 
                const isActive = events.some(event => event.active);
                this.setState({ 
                    events, 
                    isActive, 
                    isLoading: false });
            })
            .catch(error => {
                console.error('Fetch error:', error);
                this.setState({ 
                    events: [], 
                    isLoading: false 
                });   
            })
    };

    private updateEvent = (record: EventClass): void => {
        fetch('api/settings/UpdateEvent', {
            headers: {'Content-type': 'application/json'},
            method: 'POST',
            body: JSON.stringify({
                'Event': record
            })
        })
        .then(() => { 
            const updatedEvents = this.state.events.map(item =>
                item.id !== record.id ? item : { ...item, active: !item.active }
            );
            this.setState(prevState => ({  
                events: prevState.events.map(item => 
                    item.id !== record.id
                    ? item
                    : { ...item, active: !item.active }
                ),
                isActive: !this.state.isActive
            }));
            this.props.onEventUpdate(updatedEvents, this.getActiveEvent(updatedEvents));
        })
        .catch(error => { 
            console.error('Update error:', error);
        });
    };

    private getActiveEvent(events: Array<EventClass>): EventClass | null { 
        return events.find(event => event.active) || null;
    }

    EventsConstruct: React.FC<any> = () => {
        const columns = [
            {
                title: "Event",
                dataIndex: 'name',
            },
            {
                title: "Akivieren",
                align: 'center' as const, 
                width: 150,
                render: (record: EventClass) => {
                    return (
                        <Switch 
                            value={record.active}
                            disabled={!record.active && this.state.isActive}
                            onChange={() => { this.updateEvent(record) }} 
                        />
                    )
                }
            },
        ];

        return (
            <Table
                className="components-table-demo-nested"
                columns={columns}
                dataSource={this.state.events}
                pagination={false}
                rowKey={(record) => record.name}
                locale={{ emptyText: (<span><img src={EmptyData} /></span>) }}
            />
        );
    }
}