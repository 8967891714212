import React from 'react'
import PlayerFrame from './PlayerFrame';

import { Flex, List, Spin, Typography } from 'antd';

const { Text } = Typography;

import { GeneralClass } from '../../class/SettingsClass';
import { GeneralViewModel } from '../../class/viewModel/SystemViewClass';
import { DynamicViewModel, FixViewModel } from '../../class/viewModel/PlaylistViewClass';
import { DynamicClass, FixClass } from '../../class/PlaylistClass';
import Helper from '../../classes/Helper';

interface State {
    settingsGeneral: GeneralClass,
    playlistFix: Array<FixClass>,
    playlistDynamic: Array<DynamicClass>,
    playlist: Array<DynamicClass>,
    playlistPlaying: Array<DynamicClass>,
    isLoading: boolean,
}

export default class Player extends React.Component<any, State> {
  private interval: NodeJS.Timeout;

  constructor(props: any) {
      super(props);
      this.state = {
          settingsGeneral: new GeneralClass(),
          playlistFix: [],
          playlistDynamic: [],
          playlist: [],
          playlistPlaying: [],
          isLoading: true,
      }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }
    
  componentDidMount() {
      this.getData();
      this.interval = setInterval(this.checkForNewPlaylistItems, 10000);
  }

  checkForNewPlaylistItems = () => {
    fetch('api/playlist/GetDynamic', {
      headers: { 'Content-type': 'application/json' },
      method: 'GET',
    })
      .then(response => response.json())
      .then((data: DynamicViewModel) => {
        const dynamicPlaylist = Helper.ConvertData(data.dynamicResponse, DynamicClass) as Array<DynamicClass>;
        dynamicPlaylist.sort((a, b) => a.order - b.order);

        this.setState(prevState => {
          const updatedPlaylist = [...dynamicPlaylist, ...prevState.playlistFix];
        
          return { playlist: updatedPlaylist };
        });
      })
      .catch(error => {
        console.error('Fetch error:', error);
      });
  };

  handlePlayerStateChange = () => {
    if (this.state.playlistPlaying[0].videoID === this.state.playlistFix[0].videoID) {
      const newPlaylistFix = this.state.playlistFix.slice(1);

      this.setState(prevState => ({
        ...prevState,
        playlistFix: newPlaylistFix,
        playlist: [...prevState.playlistDynamic, ...newPlaylistFix],
        playlistPlaying: [...prevState.playlistDynamic, ...newPlaylistFix],
      }));
    }

    if (this.state.playlistPlaying[0].videoID === this.state.playlist[0].videoID) {
      this.deletePlaylistDynamic(this.state.playlistPlaying[0]);
      const newPlaylist = this.state.playlist.slice(1);

      this.setState(prevState => ({
        ...prevState,
        playlist: newPlaylist,
        playlistPlaying: newPlaylist,
      }))
    }
  };

  private deletePlaylistDynamic = (record: DynamicClass): void => {
    fetch('api/playlist/DeleteDynamic', {
        headers: {'Content-type': 'application/json'},
        method: 'POST',
        body: JSON.stringify({
          'DynamicRequest': record,
        })
    })
  };

  private shuffleArray = (array: Array<any>): Array<any> => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;    
  }

  public render() {
      const { isLoading, playlistPlaying, settingsGeneral } = this.state;
      const currentVideoID = playlistPlaying.length > 0 ? playlistPlaying[0].videoID : '';
  
      return (
        <>
          {isLoading ? <Spin size='large' fullscreen/> :
            <>
              <div className='playerFrame'>
                <PlayerFrame
                    videoId={currentVideoID}
                    width={settingsGeneral.playerWidth.toString()}
                    height={settingsGeneral.playerHeight.toString()}
                    onStateChange={this.handlePlayerStateChange}
                />
              </div>
              <List
                className='player-list'
                itemLayout="horizontal"
                dataSource={this.state.playlist}
                size='large'
                renderItem={(item) => (
                  <List.Item>
                    <List.Item.Meta
                      title={
                        <Flex justify='center'>
                          <Text
                            style={{ width: 250, color: 'white' }}
                            ellipsis={true}
                          >
                            {item.table == null ? item.title : (<><Text style={{ color: 'orange' }}>{item.table}</Text> - {item.title}</>)}
                          </Text>
                        </Flex>
                      }
                    />
                  </List.Item>
                )}
              />
            </>
          }
        </>
      );
    }

    private getData = (): void => {
      fetch('api/settings/GetSettingsGeneral', {
        headers: { 'Content-type': 'application/json' },
        method: 'GET',
      })
        .then(response => response.json())
        .then((data: GeneralViewModel) => {
          this.setState({ settingsGeneral: Helper.ConvertData(data.general, GeneralClass) as GeneralClass });
        })
        .catch(error => {
          console.error('Fetch error:', error);
          this.setState({ settingsGeneral: new GeneralClass() });
        })
        .then(() => {
          return Promise.all([
            fetch('api/playlist/GetFix', {
              headers: { 'Content-type': 'application/json' },
              method: 'GET',
            })
              .then(response => response.json())
              .then((data: FixViewModel) => {
                const fixPlaylist = Helper.ConvertData(data.fixResponse, FixClass) as Array<FixClass>;
                return this.shuffleArray(fixPlaylist);
              })
              .catch(error => {
                console.error('Fetch error:', error);
                return [];
              }),
    
            fetch('api/playlist/GetDynamic', {
              headers: { 'Content-type': 'application/json' },
              method: 'GET',
            })
              .then(response => response.json())
              .then((data: DynamicViewModel) => {
                const dynamicPlaylist = Helper.ConvertData(data.dynamicResponse, DynamicClass) as Array<DynamicClass>;
                dynamicPlaylist.sort((a, b) => a.order - b.order); 
                return dynamicPlaylist;
              })
              .catch(error => {
                console.error('Fetch error:', error);
                return [];
              })
          ]);
        })
        .then(([fixPlaylist, dynamicPlaylist]) => {
          this.setState({
            playlistFix: fixPlaylist,
            playlistDynamic: dynamicPlaylist,
            playlist: [...dynamicPlaylist, ...fixPlaylist],
            playlistPlaying: [...dynamicPlaylist, ...fixPlaylist],
            isLoading: false
          });
        })
        .catch(error => {
          console.error('Fetch error:', error);
        });
    }   
}