/* eslint-disable */
// @ts-nocheck

export namespace Helper {
    export function ConvertData<T>(pData: any, type: (new (any) => T)): T | T[] {
        if (Array.isArray(pData)) {
            const resultData: Array<T> = [];
            pData.forEach((item) => {
                resultData.push(convert(item, type));
            });
            return resultData;
        }
        return convert(pData, type);
    }

    function convert<T>(pData: any, type: (new (any) => T)): T {
        const result = new type(null);
        Object.keys(result).forEach((probName: string) => {
            const value = pData[Object.keys(pData).find(key => key.toLowerCase() === probName.toLowerCase())];
            result[probName] = value;
        });
        return result;
    }
}
export default Helper;