import * as React from 'react';
import { Navigate } from 'react-router-dom';
import { allowedAdmin } from './config';

interface RequireTableParamProps {
  children: React.ReactNode;
}

const TableCheck: React.FC<RequireTableParamProps> = ({ children }) => {
  const queryParams = new URLSearchParams(window.location.search);
  const token = queryParams.get('admin');
  const key = token ? allowedAdmin[token] : null;

  if (!key) {
    return <Navigate to="/error" />;
  }

  sessionStorage.setItem('user', key);
  return <>{children}</>;
};

export default TableCheck;
