export class TimesClass {
    public monday: boolean;
    public mondayAt: string;
    public mondayTo: string;
    public tuesday: boolean;
    public tuesdayAt: string;
    public tuesdayTo: string;
    public wednesday: boolean;
    public wednesdayAt: string;
    public wednesdayTo: string;
    public thursday: boolean;
    public thursdayAt: string;
    public thursdayTo: string;
    public friday: boolean;
    public fridayAt: string;
    public fridayTo: string;
    public saturday: boolean;
    public saturdayAt: string;
    public saturdayTo: string;
    public sunday: boolean;
    public sundayAt: string;
    public sundayTo: string;
    public vacation: boolean;

    constructor() {
        this.monday = false;
        this.mondayAt = '';
        this.mondayTo = '';
        this.tuesday = false;
        this.tuesdayAt = '';
        this.tuesdayTo = '';
        this.wednesday = false;
        this.wednesdayAt = '';
        this.wednesdayTo = '';
        this.thursday = false;
        this.thursdayAt = '';
        this.thursdayTo = '';
        this.friday = false;
        this.fridayAt = '';
        this.fridayTo = '';
        this.saturday = false;
        this.saturdayAt = '';
        this.saturdayTo = '';
        this.sunday = false;
        this.sundayAt = ''
        this.sundayTo = '';
        this.vacation = false;
    }
}

export class GeneralClass {
    public playerWidth: number;
    public playerHeight: number;
    public statistics: number;
    public blockDuration: number;

    constructor() {
        this.playerWidth = 0;
        this.playerHeight = 0;
        this.statistics = 0;
        this.blockDuration = 0;
    }
}

export class TableClass {
    public tableID: number;
    public blocked: boolean;
    public permaBlocked: boolean;

    constructor() {
        this.tableID = 0;
        this.blocked = false;
        this.permaBlocked = false;
    }
}

export class FeedbackClass {
    public id: number;
    public description: string;
    public name: string;
    public created: string;
    public rating: number;

    constructor() {
        this.id = 0;
        this.description = '';
        this.name = '';
        this.created = '';
        this.rating = 0;
    }
}

export class FeedbackRatingClass {
    public smile: number;
    public meh: number;
    public frown: number;

    constructor() {
        this.smile = 0;
        this.meh = 0;
        this.frown = 0;
    }
}

export class EventClass {
    public id: number;
    public name: string;
    public active: boolean;

    constructor() {
        this.id = 0;
        this.name = '';
        this.active = false;
    }
}