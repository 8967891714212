export class FixClass {
    public videoID: string;
    public title: string;
    public order: number;
    public activated: boolean;
    public table: number;

    constructor() {
        this.videoID = '';
        this.title = '';
        this.order = 0;
        this.activated = false;
        this.table = 0;
    }
}

export class DynamicClass {
    public videoID: string;
    public title: string;
    public order: number;
    public table: number;

    constructor() {
        this.videoID = '';
        this.title = '';
        this.order = 0;
        this.table = 0;
    }
}