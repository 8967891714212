import * as React from 'react';
import { Navigate } from 'react-router-dom';
import { allowedTables } from './config';

interface RequireTableParamProps {
  children: React.ReactNode;
}

const TableCheck: React.FC<RequireTableParamProps> = ({ children }) => {
  const queryParams = new URLSearchParams(window.location.search);
  const token = queryParams.get('table');
  const table = token ? allowedTables[token] : null;

  if (!table) {
    return <Navigate to="/error" />;
  }

  sessionStorage.setItem('table', table);
  return <>{children}</>;
};

export default TableCheck;
