import React, { createRef } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import customParseFormat from "dayjs/plugin/customParseFormat";

import { Card, Space, Switch, TimePicker, Typography, Divider, Flex, Spin, InputNumber, Table, Button, Modal, Input, Statistic } from 'antd';
import { DeleteOutlined, PlusOutlined, CheckOutlined, SmileOutlined, FrownOutlined, MehOutlined, CloseOutlined  } from '@ant-design/icons';
import { TimesViewModel, GeneralViewModel, FeedbackRatingViewModel } from '../../class/viewModel/SystemViewClass';
import { TimesClass, GeneralClass, FeedbackRatingClass } from '../../class/SettingsClass';
import { FixClass } from '../../class/PlaylistClass';
import Helper from '../../classes/Helper';
import { FixViewModel } from '../../class/viewModel/PlaylistViewClass';
import EmptyData from '../../Resources/Empty.png';

const { Title } = Typography;
dayjs.extend(customParseFormat);

interface State {
    isLoading: boolean,
    showAddVideo: boolean,
    settingsTimes: TimesClass,
    settingsGeneral: GeneralClass,
    playlistFix: Array<FixClass>,
    feedbackRating: FeedbackRatingClass,
    videoLink: string
}

export default class Settings extends React.Component<any, State> {
    private inputRef: React.RefObject<HTMLInputElement>;

    constructor(props: any) {
        super(props);
        this.state = {
            isLoading: true,
            showAddVideo: false,
            settingsTimes: new TimesClass(),
            settingsGeneral: new GeneralClass(),
            feedbackRating: new FeedbackRatingClass(),
            playlistFix: [],
            videoLink: ''
        };
        this.inputRef = createRef<HTMLInputElement>();
    }

    componentDidUpdate(prevState) {
        if (!prevState.showAddVideo && this.state.showAddVideo) {
            this.inputRef.current.focus();
        }
    }

    componentDidMount() {
        this.getData();
    }

    render() {
        return (<>
            {this.state.isLoading ? <Spin size='large' fullscreen/> : <></>}
            <Space direction='horizontal'>
                <Card title="Arbeitszeiten">
                    <Space direction="vertical">
                        <Flex>
                            <Title className='settings-card-title-vacation'>Urlaub</Title>
                            <Switch 
                                checked={this.state.settingsTimes.vacation} 
                                onChange={(checked: boolean) => this.setState(prevState => ({ settingsTimes: {...prevState.settingsTimes, vacation: checked }}), this.updateSettingsTimes)} 
                            />
                        </Flex>

                        <Card title="Montag" className='settings-card' extra={
                            <Switch 
                                disabled={this.state.settingsTimes.vacation} 
                                checked={this.state.settingsTimes.monday} 
                                onChange={(checked: boolean) => this.setState(prevState => ({ settingsTimes: {...prevState.settingsTimes, monday: checked }}), this.updateSettingsTimes)} />
                            }>
                            <Space size={'small'}>
                                <TimePicker 
                                    needConfirm={false} 
                                    showNow={false}
                                    format={'HH:mm'} 
                                    placeholder='Bis' 
                                    value={this.state.settingsTimes.mondayTo ? dayjs(this.state.settingsTimes.mondayTo, 'HH:mm') : null}
                                    onChange={(_date: Dayjs, dateString: string) => { this.setState(prevState => ({ settingsTimes: {...prevState.settingsTimes, mondayTo: dateString}}), this.updateSettingsTimes); }}
                                    disabled={!this.state.settingsTimes.monday || this.state.settingsTimes.vacation} 
                                />
                                <Divider type="vertical" style={{ height: "20px", borderColor: "rgba(0, 0, 0, 0.25)" }} />
                                <TimePicker 
                                    needConfirm={false} 
                                    showNow={false}
                                    format={'HH:mm'} 
                                    placeholder='Ab' 
                                    value={this.state.settingsTimes.mondayAt ? dayjs(this.state.settingsTimes.mondayAt, 'HH:mm') : null}
                                    onChange={(_date: Dayjs, dateString: string) => {
                                        this.setState(prevState => ({ settingsTimes: {...prevState.settingsTimes, mondayAt: dateString}}), this.updateSettingsTimes);
                                    }}
                                    disabled={!this.state.settingsTimes.monday || this.state.settingsTimes.vacation} 
                                />
                            </Space>
                        </Card>

                        <Card title="Dienstag" className='settings-card' extra={
                            <Switch 
                                disabled={this.state.settingsTimes.vacation} 
                                checked={this.state.settingsTimes.tuesday} 
                                onChange={(checked: boolean) => this.setState(prevState => ({ settingsTimes: {...prevState.settingsTimes, tuesday: checked }}), this.updateSettingsTimes)} />
                            }>
                            <Space size={'small'}>
                                <TimePicker 
                                    needConfirm={false} 
                                    showNow={false}
                                    format={'HH:mm'} 
                                    placeholder='Bis' 
                                    value={this.state.settingsTimes.tuesdayTo ? dayjs(this.state.settingsTimes.tuesdayTo, 'HH:mm') : null}
                                    onChange={(_date: Dayjs, dateString: string) => {
                                        this.setState(prevState => ({ settingsTimes: {...prevState.settingsTimes, tuesdayTo: dateString}}), this.updateSettingsTimes);
                                    }}
                                    disabled={!this.state.settingsTimes.tuesday || this.state.settingsTimes.vacation} 
                                />
                                <Divider type="vertical" style={{ height: "20px", borderColor: "rgba(0, 0, 0, 0.25)" }} />
                                <TimePicker 
                                    needConfirm={false} 
                                    showNow={false}
                                    format={'HH:mm'} 
                                    placeholder='Ab' 
                                    value={this.state.settingsTimes.tuesdayAt ? dayjs(this.state.settingsTimes.tuesdayAt, 'HH:mm') : null}
                                    onChange={(_date: Dayjs, dateString: string) => {
                                        this.setState(prevState => ({ settingsTimes: {...prevState.settingsTimes, tuesdayAt: dateString}}), this.updateSettingsTimes);
                                    }}
                                    disabled={!this.state.settingsTimes.tuesday || this.state.settingsTimes.vacation} 
                                />
                            </Space>
                        </Card>

                        <Card title="Mittwoch" className='settings-card' extra={
                            <Switch 
                                disabled={this.state.settingsTimes.vacation} 
                                checked={this.state.settingsTimes.wednesday} 
                                onChange={(checked: boolean) => this.setState(prevState => ({ settingsTimes: {...prevState.settingsTimes, wednesday: checked }}), this.updateSettingsTimes)} />
                            }>
                            <Space size={'small'}>
                                <TimePicker 
                                    needConfirm={false} 
                                    showNow={false}
                                    format={'HH:mm'} 
                                    placeholder='Bis' 
                                    value={this.state.settingsTimes.wednesdayTo ? dayjs(this.state.settingsTimes.wednesdayTo, 'HH:mm') : null}
                                    onChange={(_date: Dayjs, dateString: string) => {
                                        this.setState(prevState => ({ settingsTimes: {...prevState.settingsTimes, wednesdayTo: dateString}}), this.updateSettingsTimes);
                                    }}
                                    disabled={!this.state.settingsTimes.wednesday || this.state.settingsTimes.vacation} 
                                />
                                <Divider type="vertical" style={{ height: "20px", borderColor: "rgba(0, 0, 0, 0.25)" }} />
                                <TimePicker 
                                    needConfirm={false} 
                                    showNow={false}
                                    format={'HH:mm'} 
                                    placeholder='Ab' 
                                    value={this.state.settingsTimes.wednesdayAt ? dayjs(this.state.settingsTimes.wednesdayAt, 'HH:mm') : null}
                                    onChange={(_date: Dayjs, dateString: string) => {
                                        this.setState(prevState => ({ settingsTimes: {...prevState.settingsTimes, wednesdayAt: dateString}}), this.updateSettingsTimes);
                                    }}
                                    disabled={!this.state.settingsTimes.wednesday || this.state.settingsTimes.vacation} 
                                />
                            </Space>
                        </Card>

                        <Card title="Donnerstag" className='settings-card' extra={
                            <Switch 
                                disabled={this.state.settingsTimes.vacation} 
                                checked={this.state.settingsTimes.thursday} 
                                onChange={(checked: boolean) => this.setState(prevState => ({ settingsTimes: {...prevState.settingsTimes, thursday: checked }}), this.updateSettingsTimes)} />
                            }>
                            <Space size={'small'}>
                                <TimePicker 
                                    needConfirm={false} 
                                    showNow={false}
                                    format={'HH:mm'} 
                                    placeholder='Bis' 
                                    value={this.state.settingsTimes.thursdayTo ? dayjs(this.state.settingsTimes.thursdayTo, 'HH:mm') : null}
                                    onChange={(_date: Dayjs, dateString: string) => {
                                        this.setState(prevState => ({ settingsTimes: {...prevState.settingsTimes, thursdayTo: dateString}}), this.updateSettingsTimes);
                                    }}
                                    disabled={!this.state.settingsTimes.thursday || this.state.settingsTimes.vacation} 
                                />
                                <Divider type="vertical" style={{ height: "20px", borderColor: "rgba(0, 0, 0, 0.25)" }} />
                                <TimePicker 
                                    needConfirm={false} 
                                    showNow={false}
                                    format={'HH:mm'} 
                                    placeholder='Ab' 
                                    value={this.state.settingsTimes.thursdayAt ? dayjs(this.state.settingsTimes.thursdayAt, 'HH:mm') : null}
                                    onChange={(_date: Dayjs, dateString: string) => {
                                        this.setState(prevState => ({ settingsTimes: {...prevState.settingsTimes, thursdayAt: dateString}}), this.updateSettingsTimes);
                                    }}
                                    disabled={!this.state.settingsTimes.thursday || this.state.settingsTimes.vacation} 
                                />
                            </Space>
                        </Card>

                        <Card title="Freitag" className='settings-card' extra={
                            <Switch 
                                disabled={this.state.settingsTimes.vacation} 
                                checked={this.state.settingsTimes.friday} 
                                onChange={(checked: boolean) => this.setState(prevState => ({ settingsTimes: {...prevState.settingsTimes, friday: checked }}), this.updateSettingsTimes)} />
                            }>
                            <Space size={'small'}>
                                <TimePicker 
                                    needConfirm={false} 
                                    showNow={false}
                                    format={'HH:mm'} 
                                    placeholder='Bis' 
                                    value={this.state.settingsTimes.fridayTo ? dayjs(this.state.settingsTimes.fridayTo, 'HH:mm') : null}
                                    onChange={(_date: Dayjs, dateString: string) => {
                                        this.setState(prevState => ({ settingsTimes: {...prevState.settingsTimes, fridayTo: dateString}}), this.updateSettingsTimes);
                                    }}
                                    disabled={!this.state.settingsTimes.friday || this.state.settingsTimes.vacation} 
                                />
                                <Divider type="vertical" style={{ height: "20px", borderColor: "rgba(0, 0, 0, 0.25)" }} />
                                <TimePicker 
                                    needConfirm={false} 
                                    showNow={false}
                                    format={'HH:mm'} 
                                    placeholder='Ab' 
                                    value={this.state.settingsTimes.fridayAt ? dayjs(this.state.settingsTimes.fridayAt, 'HH:mm') : null}
                                    onChange={(_date: Dayjs, dateString: string) => {
                                        this.setState(prevState => ({ settingsTimes: {...prevState.settingsTimes, fridayAt: dateString}}), this.updateSettingsTimes);
                                    }}
                                    disabled={!this.state.settingsTimes.friday || this.state.settingsTimes.vacation} 
                                />
                            </Space>
                        </Card>

                        <Card title="Samstag" className='settings-card' extra={
                            <Switch 
                                disabled={this.state.settingsTimes.vacation} 
                                checked={this.state.settingsTimes.saturday} 
                                onChange={(checked: boolean) => this.setState(prevState => ({ settingsTimes: {...prevState.settingsTimes, saturday: checked }}), this.updateSettingsTimes)} />
                            }>
                            <Space size={'small'}>
                                <TimePicker 
                                    needConfirm={false} 
                                    showNow={false}
                                    format={'HH:mm'} 
                                    placeholder='Bis' 
                                    value={this.state.settingsTimes.saturdayTo ? dayjs(this.state.settingsTimes.saturdayTo, 'HH:mm') : null}
                                    onChange={(_date: Dayjs, dateString: string) => {
                                        this.setState(prevState => ({ settingsTimes: {...prevState.settingsTimes, saturdayTo: dateString}}), this.updateSettingsTimes);
                                    }}
                                    disabled={!this.state.settingsTimes.saturday || this.state.settingsTimes.vacation} 
                                />
                                <Divider type="vertical" style={{ height: "20px", borderColor: "rgba(0, 0, 0, 0.25)" }} />
                                <TimePicker 
                                    needConfirm={false} 
                                    showNow={false}
                                    format={'HH:mm'} 
                                    placeholder='Ab' 
                                    value={this.state.settingsTimes.saturdayAt ? dayjs(this.state.settingsTimes.saturdayAt, 'HH:mm') : null}
                                    onChange={(_date: Dayjs, dateString: string) => {
                                        this.setState(prevState => ({ settingsTimes: {...prevState.settingsTimes, saturdayAt: dateString}}), this.updateSettingsTimes);
                                    }}
                                    disabled={!this.state.settingsTimes.saturday || this.state.settingsTimes.vacation} 
                                />
                            </Space>
                        </Card>

                        <Card title="Sonntag" className='settings-card' extra={
                            <Switch 
                                disabled={this.state.settingsTimes.vacation} 
                                checked={this.state.settingsTimes.sunday} 
                                onChange={(checked: boolean) => this.setState(prevState => ({ settingsTimes: {...prevState.settingsTimes, sunday: checked }}), this.updateSettingsTimes)} />
                            }>
                            <Space size={'small'}>
                                <TimePicker 
                                    needConfirm={false} 
                                    showNow={false}
                                    format={'HH:mm'} 
                                    placeholder='Bis' 
                                    value={this.state.settingsTimes.sundayTo ? dayjs(this.state.settingsTimes.sundayTo, 'HH:mm') : null}
                                    onChange={(_date: Dayjs, dateString: string) => {
                                        this.setState(prevState => ({ settingsTimes: {...prevState.settingsTimes, sundayTo: dateString}}), this.updateSettingsTimes);
                                    }}
                                    disabled={!this.state.settingsTimes.sunday || this.state.settingsTimes.vacation} 
                                />
                                <Divider type="vertical" style={{ height: "20px", borderColor: "rgba(0, 0, 0, 0.25)" }} />
                                <TimePicker 
                                    needConfirm={false} 
                                    showNow={false}
                                    format={'HH:mm'} 
                                    placeholder='Ab' 
                                    value={this.state.settingsTimes.sundayAt ? dayjs(this.state.settingsTimes.sundayAt, 'HH:mm') : null}
                                    onChange={(_date: Dayjs, dateString: string) => {
                                        this.setState(prevState => ({ settingsTimes: {...prevState.settingsTimes, sundayAt: dateString}}), this.updateSettingsTimes);
                                    }}
                                    disabled={!this.state.settingsTimes.sunday || this.state.settingsTimes.vacation} 
                                />
                            </Space>
                        </Card>
                    </Space>
                </Card>

                <Divider type="vertical" style={{ height: '180vh', borderColor: "rgba(0, 0, 0, 0.25)" }} />

                <Space direction='vertical'>
                    <Card title="Allgemein">
                        <Space direction='vertical' size={'large'}>
                            <Card title="Player" className='settings-card'>
                                <Space direction='vertical'>
                                    <Flex>
                                        <Title className='settings-card-title'>Länge</Title>
                                        <InputNumber  
                                            className='settings-card-input'
                                            value={this.state.settingsGeneral.playerWidth}
                                            onChange={(value: number) => {
                                                this.setState(prevState => ({ settingsGeneral: {...prevState.settingsGeneral, playerWidth : value}}), this.updateSettingsGeneral);
                                            }}
                                        />
                                    </Flex>
                                    <Flex>
                                        <Title className='settings-card-title'>Höhe</Title>
                                        <InputNumber  
                                            className='settings-card-input'
                                            value={this.state.settingsGeneral.playerHeight}
                                            onChange={(value: number) => {
                                                this.setState(prevState => ({ settingsGeneral: {...prevState.settingsGeneral, playerHeight: value}}), this.updateSettingsGeneral);
                                            }}
                                        />
                                    </Flex>
                                </Space>
                            </Card>
                            <Card className='settings-card'>
                                <Space direction='vertical'>
                                    <Flex>
                                        <Title className='settings-card-title-block'>Gast sperren</Title>
                                        <TimePicker 
                                            className='settings-card-input-block'
                                            needConfirm={false} 
                                            showNow={false}
                                            format={'mm'} 
                                            placeholder='Minuten' 
                                            value={this.state.settingsGeneral.blockDuration ? dayjs().set('minute', this.state.settingsGeneral.blockDuration) : null }
                                            onChange={(_date: Dayjs, dateString: string) => { 
                                                const blockDuration = parseInt(dateString, 10);
                                                this.setState(prevState => ({ 
                                                    settingsGeneral: {...prevState.settingsGeneral, blockDuration}
                                                }), 
                                                this.updateSettingsGeneral); 
                                            }}
                                        />
                                    </Flex>
                                </Space>
                            </Card>
                        </Space>
                    </Card>
                    <Space direction='vertical' className='settings-card-general'>
                        <Card title="Gast Lieder hinzugefügt">
                            <Statistic value={this.state.settingsGeneral.statistics} />
                        </Card>
                        <Card title="Feedback">
                            <Flex justify='space-between'>
                                <Statistic value={this.state.feedbackRating.smile} prefix={<SmileOutlined style={{ color: '#32CD32', fontSize: '1.8em' }} />} />
                                <Statistic value={this.state.feedbackRating.meh} prefix={<MehOutlined style={{ color: 'orange', fontSize: '1.8em' }} />} />
                                <Statistic value={this.state.feedbackRating.frown} prefix={<FrownOutlined style={{ color: 'red', fontSize: '1.8em' }} />} />
                            </Flex>
                        </Card>
                    </Space>
                </Space>

                <Divider type="vertical" style={{ height: '110vh', borderColor: "rgba(0, 0, 0, 0.25)" }} />

                <this.TableConstruct/>
                <Modal
                    title={"Hinzufügen"}
                    centered
                    open={this.state.showAddVideo}
                    onCancel={() => this.setState({ showAddVideo: false, videoLink: '' })}
                    footer={null}
                    width={500}
                >
                    <Space.Compact style={{ width: '100%' }}>
                        <Input 
                            ref={this.inputRef as any}
                            placeholder="Youtube Link" 
                            value={this.state.videoLink}
                            onChange={(event) => this.setState({ videoLink: event.target.value })}
                            
                        />
                        <Button 
                            type="primary" 
                            icon={<CheckOutlined />}
                            onClick={() => this.AddPlaylistFix()} 
                        />
                    </Space.Compact>
                </Modal>
            </Space>
            </>
        );
    }

    private getData = (): void => {
        fetch('api/settings/GetSettingsTimes', {
            headers: {'Content-type': 'application/json'},
            method: 'GET',
        })
            .then(response => response.json())
            .then((data: TimesViewModel) => {
                this.setState({ settingsTimes: Helper.ConvertData(data.times, TimesClass) as TimesClass });
            })
            .catch(error => {
                console.error('Fetch error:', error);
                this.setState({ 
                    settingsTimes: new TimesClass(), 
                    isLoading: false 
                });   
            })
            .then(() => {
                fetch('api/settings/GetSettingsGeneral', {
                    headers: {'Content-type': 'application/json'},
                    method: 'GET',
                })
                .then(response => response.json())
                .then((data: GeneralViewModel) => {
                    this.setState({ settingsGeneral: Helper.ConvertData(data.general, GeneralClass) as GeneralClass });
                })
                .catch(error => {
                    console.error('Fetch error:', error);
                    this.setState({  
                        settingsGeneral: new GeneralClass(),
                        isLoading: false  
                    });   
                })
                .then(() => {
                    fetch('api/settings/GetFeedbackRating', {
                        headers: {'Content-type': 'application/json'},
                        method: 'GET',
                    })
                    .then(response => response.json())
                    .then((data: FeedbackRatingViewModel) => {
                        this.setState({ feedbackRating: Helper.ConvertData(data.rating, FeedbackRatingClass) as FeedbackRatingClass });
                    })
                    .catch(error => {
                        console.error('Fetch error:', error);
                        this.setState({  
                            feedbackRating: new FeedbackRatingClass(),
                            isLoading: false  
                        });   
                    })  
                    .then(() => {
                        fetch('api/playlist/GetFixAll', {
                            headers: {'Content-type': 'application/json'},
                            method: 'GET',
                        })
                        .then(response => response.json())
                        .then((data: FixViewModel) => {
                            this.setState({ playlistFix: Helper.ConvertData(data.fixResponse, FixClass) as Array<FixClass>, isLoading: false });
                        })
                        .catch(error => {
                            console.error('Fetch error:', error);
                            this.setState({ 
                                playlistFix: [], 
                                isLoading: false 
                            });   
                        });                
                    });  
            });  
        });
    };

    private updateSettingsTimes = (): void => {
        fetch('api/settings/UpdateSettingsTimes', {
            headers: {'Content-type': 'application/json'},
            method: 'POST',
            body: JSON.stringify({
                'Times': this.state.settingsTimes,
            })
        })
    };

    private updateSettingsGeneral = (): void => {
        fetch('api/settings/UpdateSettingsGeneral', {
            headers: {'Content-type': 'application/json'},
            method: 'POST',
            body: JSON.stringify({
                'General': this.state.settingsGeneral,
            })
        })
    };

    private updatePlaylistFix = (record: FixClass): void => {
        fetch('api/playlist/UpdateFix', {
            headers: {'Content-type': 'application/json'},
            method: 'POST',
            body: JSON.stringify({
                'FixRequest': record,
            })
        })
        .then(() => {
            this.setState(prevState => ({  
                playlistFix: prevState.playlistFix.map(item => 
                    item.videoID !== record.videoID
                    ? item
                    : { ...item, activated: !item.activated }
                )
            }));
        });
    };

    private deletePlaylistFix = (record: FixClass): void => {
        fetch('api/playlist/DeleteFix', {
            headers: {'Content-type': 'application/json'},
            method: 'POST',
            body: JSON.stringify({
                'FixRequest': record,
            })
        })
        .then(() => { 
            const items = this.state.playlistFix.filter(item => item.videoID !== record.videoID);
            this.setState({ playlistFix: items });
        })
    };

    private AddPlaylistFix = async (): Promise<void> => {
        try {
            await fetch('api/playlist/AddFix', {
                headers: {'Content-type': 'application/json'},
                method: 'POST',
                body: JSON.stringify({
                    FixRequest: {
                        VideoLink: this.state.videoLink
                    }
                })
            });

            const response = await fetch('api/playlist/GetFix', {
                headers: {'Content-type': 'application/json'},
                method: 'GET',
            });

            const data: FixViewModel = await response.json();
            this.setState({ 
                playlistFix: Helper.ConvertData(data.fixResponse, FixClass) as Array<FixClass>,
                videoLink: '',
                showAddVideo: false
            });            
        } catch(error) {
            this.setState({ 
                playlistFix: [], 
                videoLink: ''
            });   
        }
    };

    TableConstruct: React.FC<any> = () => {
        const columns = [
            {
                title: "Titel",
                dataIndex: 'title',
                width: 1000,
                filterSearch: true,
                filters: this.state.playlistFix.map((item) => ({
                    text: item.title,
                    value: item.title
                })),
                onFilter: (value, record) => record.title.includes(value as string), 
                render: (text) => text.length > 20 ? `${text.substring(0, 40)}...` : text
            },
            {
                render: (record: FixClass) => {
                    return (
                        <Space>
                            {record.activated ? 
                                <Button 
                                    icon={<CloseOutlined />}
                                    onClick={() => { this.updatePlaylistFix(record) }}
                                />
                            : 
                                <Button 
                                    icon={<CheckOutlined />}
                                    onClick={() => { this.updatePlaylistFix(record) }}
                                />
                            }
                            <Button
                                danger
                                icon={<DeleteOutlined />}
                                onClick={() => { this.deletePlaylistFix(record) }}
                            />
                        </Space>
                    )
                }
            },
        ];

        return (
            <Table
                columns={columns}
                dataSource={this.state.playlistFix}
                rowKey={(record) => record.videoID}
                locale={{ emptyText: (<span><img src={EmptyData} /></span>) }}
                pagination={{ pageSize: 4 }}
                rowClassName={(record) => !record.activated ? 'inactive-row': ''}
                title={() =>
                    <>
                        <Flex justify={'space-between'}> 
                            <Title level={4}>Playlist</Title>
                            <Button 
                                type="primary" 
                                icon={<PlusOutlined />} 
                                onClick={() => this.setState({ showAddVideo: true })}
                            />
                        </Flex>
                    </>
                }
            />
        );
    }
}