import React, { useState } from 'react';

import { Modal, Table, Typography, Divider, Input, Image, Button, Spin, Alert, Flex, Form, Layout, message } from 'antd';
import { PlusOutlined, LikeOutlined, SmileOutlined, FrownOutlined, MehOutlined, ToTopOutlined } from '@ant-design/icons';
const { Title, Paragraph } = Typography;
const { Search, TextArea } = Input;
const { Footer } = Layout;
import '../../src/css/Users.scss';

import EmptyData from '../Resources/Empty.png';

import { SearchClass} from '../class/YoutubeClass';
import { SearchViewModel } from '../class/viewModel/YoutubeViewClass';
import Helper from '../classes/Helper';
import { TimesViewModel } from '../class/viewModel/SystemViewClass';
import { FeedbackClass, TimesClass } from '../class/SettingsClass';

interface State {
    isLoading: boolean,
    modalVisible: boolean,
    modalFeedbackVisible: boolean,
    modalImprintVisible: boolean,
    modalDataProtectionVisible: boolean,
    isUserWithinTimespan: boolean,
    blocked: boolean,
    records: Array<SearchClass>,
    selectedRecord: SearchClass,
}

export default class Users extends React.Component<any, State> {
        constructor(props: any) {
        super(props);
        this.state = {
            isLoading: true,
            modalVisible: false,
            modalFeedbackVisible: false,
            modalImprintVisible: false,
            modalDataProtectionVisible: false,
            isUserWithinTimespan: false,
            blocked: false,
            records: [],
            selectedRecord: new SearchClass(),
        };
    }
    
    componentDidMount() {
        this.getData();
    }

    handleModalFeedback = () => {
        this.setState({ modalFeedbackVisible: !this.state.modalFeedbackVisible });
    };

    handleModalImprint = () => {
        this.setState({ modalImprintVisible: !this.state.modalImprintVisible });
    };

    handleModalDataProtection = () => {
        this.setState({ modalDataProtectionVisible: !this.state.modalDataProtectionVisible });
    };

    handleModalClose = () => {
        this.setState({ modalVisible: !this.state.modalVisible });
    };

    public render() {
        return (
            <>
            {this.state.isLoading ? 
                (<Spin size='large' fullscreen/>) :
                (this.state.isUserWithinTimespan ? 
                    <>
                        <div className='fixedHeader'>
                            <Flex className='fixedHeaderTitle' justify='center'>
                                <Title>Mary's</Title>
                            </Flex>
                            <Button className='fixedHeaderLike' icon={<LikeOutlined />} onClick={this.handleModalFeedback}/>
                            <Divider />
                            <Search placeholder="Youtube Suche" className='search' onSearch={this.onSearch} />
                        </div>

                        <this.TableConstruct/>

                        <Footer className='userfooter'>
                            <Flex justify='space-evenly'>
                                <Button color="default" variant="text" onClick={this.handleModalImprint}>
                                    Impressum
                                </Button>
                                <Button color="default" variant="text" onClick={this.handleModalDataProtection}>
                                    Datenschutz
                                </Button>
                            </Flex>
                        </Footer>

                        <Modal
                            title={this.state.selectedRecord.title}
                            centered
                            open={this.state.modalVisible}
                            onCancel={this.handleModalClose}
                            footer={null}
                        >
                            <Button type="primary" size={'large'} icon={<PlusOutlined />} className='buttonCenter' onClick={() => this.addToPlaylistDynamic() }>
                                Hinzufügen
                            </Button>
                        </Modal>

                        <Modal
                            title="Feedback"
                            centered
                            open={this.state.modalFeedbackVisible}
                            onCancel={this.handleModalFeedback}
                            footer={null}
                        >
                            <UserSettings 
                                closeModal={this.handleModalFeedback}
                            />
                        </Modal>

                        <Modal
                            title="Impressum"
                            centered
                            open={this.state.modalImprintVisible}
                            onCancel={this.handleModalImprint}
                            footer={null}
                        >
                            <div>
                                <Title level={3}>Impressum</Title>
                                    <Paragraph>Edgar Rinas</Paragraph>
                                    <Paragraph>Ballenstedter Str. 21</Paragraph>
                                    <Paragraph>06493 Ballenstedt</Paragraph>
                                <Title level={3}>Kontakt</Title>
                                    <Paragraph>Telefon: 017680460088</Paragraph>
                                    <Paragraph>E-Mail: info@Feisser.de</Paragraph>
                            </div>
                        </Modal>

                        <Modal
                            title="Datenschutz"
                            centered
                            open={this.state.modalDataProtectionVisible}
                            onCancel={this.handleModalDataProtection}
                            footer={null}
                        >
                            <div>
                                <Title level={3}>1. Datenschutz auf einen Blick</Title>
                                    <Title level={4}>Allgemeine Hinweise</Title>
                                        <Paragraph>
                                            Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit Ihren personenbezogenen Daten
                                            passiert, wenn Sie diese Website besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie
                                            persönlich identifiziert werden können. Ausführliche Informationen zum Thema Datenschutz entnehmen
                                            Sie unserer unter diesem Text aufgeführten Datenschutzerklärung.
                                        </Paragraph>
                                    <Title level={4}>Datenerfassung auf dieser Website</Title>
                                        <Title level={5}>Wer ist verantwortlich für die Datenerfassung auf dieser Website?</Title>
                                            <Paragraph>
                                                Die Datenverarbeitung auf dieser Website erfolgt durch den Websitebetreiber. Dessen Kontaktdaten
                                                können Sie dem Abschnitt „Hinweis zur Verantwortlichen Stelle“ in dieser Datenschutzerklärung entnehmen.
                                            </Paragraph>
                                        <Title level={5}>Wie erfassen wir Ihre Daten?</Title>
                                            <Paragraph>
                                                Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese mitteilen. Hierbei kann es sich z. B. um
                                                Daten handeln, die Sie in ein Kontaktformular eingeben.
                                            </Paragraph>
                                            <Paragraph>
                                                Andere Daten werden automatisch oder nach Ihrer Einwilligung beim Besuch der Website durch unsere ITSysteme erfasst. Das sind vor allem technische Daten (z. B. Internetbrowser, Betriebssystem oder Uhrzeit
                                                des Seitenaufrufs). Die Erfassung dieser Daten erfolgt automatisch, sobald Sie diese Website betreten.
                                            </Paragraph>
                                        <Title level={5}>Wofür nutzen wir Ihre Daten?</Title>
                                            <Paragraph>
                                                Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung der Website zu gewährleisten. Andere
                                                Daten können zur Analyse Ihres Nutzerverhaltens verwendet werden. Sofern über die Website Verträge
                                                geschlossen oder angebahnt werden können, werden die übermittelten Daten auch für Vertragsangebote,
                                                Bestellungen oder sonstige Auftragsanfragen verarbeitet.
                                            </Paragraph>
                                        <Title level={5}>Welche Rechte haben Sie bezüglich Ihrer Daten?</Title>
                                            <Paragraph>
                                                Sie haben jederzeit das Recht, unentgeltlich Auskunft über Herkunft, Empfänger und Zweck Ihrer
                                                gespeicherten personenbezogenen Daten zu erhalten. Sie haben außerdem ein Recht, die Berichtigung oder
                                                Löschung dieser Daten zu verlangen. Wenn Sie eine Einwilligung zur Datenverarbeitung erteilt haben,
                                                können Sie diese Einwilligung jederzeit für die Zukunft widerrufen. Außerdem haben Sie das Recht, unter
                                                bestimmten Umständen die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
                                                Des Weiteren steht Ihnen ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.
                                            </Paragraph>
                                            <Paragraph>
                                                Hierzu sowie zu weiteren Fragen zum Thema Datenschutz können Sie sich jederzeit an uns wenden.
                                            </Paragraph>
                                    <Title level={3}>2. Hosting</Title>
                                        <Paragraph>
                                            Wir hosten die Inhalte unserer Website bei folgendem Anbieter:
                                        </Paragraph>
                                        <Title level={5}>GoDaddy</Title>
                                            <Paragraph>
                                                Beim Besuch dieser Website werden personenbezogene Daten verarbeitet. 
                                                Dabei verarbeitete Datenkategorien: technische Verbindungsdaten des Serverzugriffs 
                                                (IP-Adresse, Datum, Uhrzeit, abgefragte Seite, Browser-Informationen). Zweck der Verarbeitung: 
                                                Überwachung der technischen Funktion und zur Erhöhung der Betriebssicherheit unseres Webservers, 
                                                Auslieferung und Bereitstellung der Website und Anonymisierung und Erstellung von Statistiken. 
                                                Die Rechtsgrundlage für die Verarbeitung: ein berechtigtes Interesse, das die Rechte und Freiheiten der 
                                                etroffenen Personen überwiegt (Art. 6 (1) f DSGVO). Berechtigte Interessen in diesem Zusammenhang: 
                                                starkes wirtschaftliches Interesse an einem sicheren und funktionierenden Betrieb der technischen Systeme. 
                                                Eine Übermittlung von Daten erfolgt: an den Auftragsverarbeiter GoDaddy.com, 
                                                LLC, Corporate Headquarters 14455 N. Hayden Rd., Ste. 226, Scottsdale, AZ 85260, USA. 
                                                Dies kann auch eine Übermittlung von personenbezogenen Daten in ein Land außerhalb der Europäischen Union bedeuten. 
                                                Die Übermittlung der Daten in die USA erfolgt aufgrund Art. 45 DSGVO iVm der Angemessenheitsentscheidung 
                                                C(2023) 4745 der Europäischen Kommission, da sich der Datenempfänger zur Einhaltung der Grundsätze der Datenverarbeitung 
                                                des Data Pricacy Frameworks (DPF) verpflichtet hat.
                                            </Paragraph>
                                        <Title level={5}>CDN-Anbieter - Cloudflare</Title>
                                            <Paragraph>
                                                Beim Besuch dieser Website werden personenbezogene Daten verarbeitet. Dabei verarbeitete Datenkategorien: 
                                                technische Verbindungsdaten des Serverzugriffs (IP-Adresse, Datum, Uhrzeit, abgefragte Seite, Browser-Informationen). 
                                                Zweck der Verarbeitung: Auslieferung und Bereitstellung der Website. Die Rechtsgrundlage für die Verarbeitung: 
                                                ein berechtigtes Interesse, das die Rechte und Freiheiten der betroffenen Personen überwiegt (Art. 6 (1) f DSGVO). 
                                                Berechtigte Interessen in diesem Zusammenhang: starkes wirtschaftliches Interesse an einem sicheren und funktionierenden 
                                                Betrieb der technischen Systeme. Eine Übermittlung von Daten erfolgt: 
                                                an den Auftragsverarbeiter Cloudflare Germany GmbH, Rosental 7, 80331 München, Deutschland. Dies kann auch eine Übermittlung 
                                                von personenbezogenen Daten in ein Land außerhalb der Europäischen Union bedeuten. Die Übermittlung der Daten erfolgt aufgrund 
                                                von Standarddatenschutzklauseln, die von der Kommission gemäß dem Prüfverfahren nach Artikel 93 Absatz 2 DSGVO erlassen wurden.
                                            </Paragraph>
                                    <Title level={3}>3. Allgemeine Hinweise und Pflichtinformationen</Title>
                                        <Title level={4}>Datenschutz</Title>
                                            <Paragraph>
                                                Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Wir behandeln Ihre
                                                personenbezogenen Daten vertraulich und entsprechend den gesetzlichen Datenschutzvorschriften sowie
                                                dieser Datenschutzerklärung.
                                            </Paragraph>
                                            <Paragraph>
                                                Wenn Sie diese Website benutzen, werden verschiedene personenbezogene Daten erhoben.
                                                Personenbezogene Daten sind Daten, mit denen Sie persönlich identifiziert werden können. Die vorliegende
                                                Datenschutzerklärung erläutert, welche Daten wir erheben und wofür wir sie nutzen. Sie erläutert auch, wie
                                                und zu welchem Zweck das geschieht.
                                            </Paragraph>
                                            <Paragraph>
                                                Wir weisen darauf hin, dass die Datenübertragung im Internet (z. B. bei der Kommunikation per E-Mail)
                                                Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht
                                                möglich.
                                            </Paragraph>
                                        <Title level={4}>Hinweis zur verantwortlichen Stelle</Title>
                                            <Paragraph>
                                                Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website ist:
                                            </Paragraph>
                                            <Paragraph>
                                                Edgar Rinas<br />
                                                Ballenstedter Str. 21, 06493 Ballenstedt
                                            </Paragraph>
                                            <Paragraph>
                                                Telefon: 017680460088<br />
                                                E-Mail: info@Feisser.de
                                            </Paragraph>
                                            <Paragraph>
                                                Verantwortliche Stelle ist die natürliche oder juristische Person, die allein oder gemeinsam mit anderen über
                                                die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten (z. B. Namen, E-Mail-Adressen o. Ä.)
                                                entscheidet.
                                            </Paragraph>
                                        <Title level={4}>Speicherdauer</Title>    
                                            <Paragraph>
                                                Soweit innerhalb dieser Datenschutzerklärung keine speziellere Speicherdauer genannt wurde, verbleiben
                                                Ihre personenbezogenen Daten bei uns, bis der Zweck für die Datenverarbeitung entfällt. Wenn Sie ein
                                                berechtigtes Löschersuchen geltend machen oder eine Einwilligung zur Datenverarbeitung widerrufen,
                                                werden Ihre Daten gelöscht, sofern wir keine anderen rechtlich zulässigen Gründe für die Speicherung Ihrer
                                                personenbezogenen Daten haben (z. B. steuer- oder handelsrechtliche Aufbewahrungsfristen); im
                                                letztgenannten Fall erfolgt die Löschung nach Fortfall dieser Gründe.
                                            </Paragraph>
                                        <Title level={4}>Allgemeine Hinweise zu den Rechtsgrundlagen der Datenverarbeitung auf dieser Website</Title>    
                                            <Paragraph>
                                                Sofern Sie in die Datenverarbeitung eingewilligt haben, verarbeiten wir Ihre personenbezogenen Daten auf
                                                Grundlage von Art. 6 Abs. 1 lit. a DSGVO bzw. Art. 9 Abs. 2 lit. a DSGVO, sofern besondere Datenkategorien
                                                nach Art. 9 Abs. 1 DSGVO verarbeitet werden. Im Falle einer ausdrücklichen Einwilligung in die Übertragung
                                                personenbezogener Daten in Drittstaaten erfolgt die Datenverarbeitung außerdem auf Grundlage von Art.
                                                49 Abs. 1 lit. a DSGVO. Sofern Sie in die Speicherung von Cookies oder in den Zugriff auf Informationen in
                                                Ihr Endgerät (z. B. via Device-Fingerprinting) eingewilligt haben, erfolgt die Datenverarbeitung zusätzlich
                                                auf Grundlage von § 25 Abs. 1 TDDDG. Die Einwilligung ist jederzeit widerrufbar. Sind Ihre Daten zur
                                                Vertragserfüllung oder zur Durchführung vorvertraglicher Maßnahmen erforderlich, verarbeiten wir Ihre
                                                Daten auf Grundlage des Art. 6 Abs. 1 lit. b DSGVO. Des Weiteren verarbeiten wir Ihre Daten, sofern diese
                                                zur Erfüllung einer rechtlichen Verpflichtung erforderlich sind auf Grundlage von Art. 6 Abs. 1 lit. c DSGVO.
                                                Die Datenverarbeitung kann ferner auf Grundlage unseres berechtigten Interesses nach Art. 6 Abs. 1 lit. f
                                                DSGVO erfolgen. Über die jeweils im Einzelfall einschlägigen Rechtsgrundlagen wird in den folgenden
                                                Absätzen dieser Datenschutzerklärung informiert.
                                            </Paragraph>
                                        <Title level={4}>Empfänger von personenbezogenen Daten</Title>    
                                            <Paragraph>
                                                Im Rahmen unserer Geschäftstätigkeit arbeiten wir mit verschiedenen externen Stellen zusammen. Dabei
                                                ist teilweise auch eine Übermittlung von personenbezogenen Daten an diese externen Stellen erforderlich.
                                                Wir geben personenbezogene Daten nur dann an externe Stellen weiter, wenn dies im Rahmen einer
                                                Vertragserfüllung erforderlich ist, wenn wir gesetzlich hierzu verpflichtet sind (z. B. Weitergabe von Daten
                                                an Steuerbehörden), wenn wir ein berechtigtes Interesse nach Art. 6 Abs. 1 lit. f DSGVO an der Weitergabe
                                                haben oder wenn eine sonstige Rechtsgrundlage die Datenweitergabe erlaubt. Beim Einsatz von
                                                Auftragsverarbeitern geben wir personenbezogene Daten unserer Kunden nur auf Grundlage eines gültigen
                                                Vertrags über Auftragsverarbeitung weiter. Im Falle einer gemeinsamen Verarbeitung wird ein Vertrag über
                                                gemeinsame Verarbeitung geschlossen.
                                            </Paragraph>
                                        <Title level={4}>Widerruf Ihrer Einwilligung zur Datenverarbeitung</Title>    
                                            <Paragraph>
                                                Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen Einwilligung möglich. Sie können eine
                                                bereits erteilte Einwilligung jederzeit widerrufen. Die Rechtmäßigkeit der bis zum Widerruf erfolgten
                                                Datenverarbeitung bleibt vom Widerruf unberührt.
                                            </Paragraph>
                                        <Title level={4}>Widerspruchsrecht gegen die Datenerhebung in besonderen Fällen sowie gegen Direktwerbung (Art. 21 DSGVO)</Title>  
                                            <Paragraph>
                                                WENN DIE DATENVERARBEITUNG AUF GRUNDLAGE VON ART. 6 ABS. 1 LIT. E ODER F DSGVO
                                                ERFOLGT, HABEN SIE JEDERZEIT DAS RECHT, AUS GRÜNDEN, DIE SICH AUS IHRER BESONDEREN
                                                SITUATION ERGEBEN, GEGEN DIE VERARBEITUNG IHRER PERSONENBEZOGENEN DATEN
                                                WIDERSPRUCH EINZULEGEN; DIES GILT AUCH FÜR EIN AUF DIESE BESTIMMUNGEN GESTÜTZTES
                                                PROFILING. DIE JEWEILIGE RECHTSGRUNDLAGE, AUF DENEN EINE VERARBEITUNG BERUHT,
                                                ENTNEHMEN SIE DIESER DATENSCHUTZERKLÄRUNG. WENN SIE WIDERSPRUCH EINLEGEN,
                                                WERDEN WIR IHRE BETROFFENEN PERSONENBEZOGENEN DATEN NICHT MEHR VERARBEITEN, ES
                                                SEI DENN, WIR KÖNNEN ZWINGENDE SCHUTZWÜRDIGE GRÜNDE FÜR DIE VERARBEITUNG
                                                NACHWEISEN, DIE IHRE INTERESSEN, RECHTE UND FREIHEITEN ÜBERWIEGEN ODER DIE
                                                VERARBEITUNG DIENT DER GELTENDMACHUNG, AUSÜBUNG ODER VERTEIDIGUNG VON
                                                RECHTSANSPRÜCHEN (WIDERSPRUCH NACH ART. 21 ABS. 1 DSGVO).
                                            </Paragraph>
                                            <Paragraph>
                                                WERDEN IHRE PERSONENBEZOGENEN DATEN VERARBEITET, UM DIREKTWERBUNG ZU BETREIBEN,
                                                SO HABEN SIE DAS RECHT, JEDERZEIT WIDERSPRUCH GEGEN DIE VERARBEITUNG SIE
                                                BETREFFENDER PERSONENBEZOGENER DATEN ZUM ZWECKE DERARTIGER WERBUNG
                                                EINZULEGEN; DIES GILT AUCH FÜR DAS PROFILING, SOWEIT ES MIT SOLCHER DIREKTWERBUNG IN
                                                VERBINDUNG STEHT. WENN SIE WIDERSPRECHEN, WERDEN IHRE PERSONENBEZOGENEN DATEN
                                                ANSCHLIESSEND NICHT MEHR ZUM ZWECKE DER DIREKTWERBUNG VERWENDET (WIDERSPRUCH
                                                NACH ART. 21 ABS. 2 DSGVO).
                                            </Paragraph>
                                        <Title level={4}>Beschwerderecht bei der zuständigen Aufsichtsbehörde</Title>  
                                            <Paragraph>
                                                Im Falle von Verstößen gegen die DSGVO steht den Betroffenen ein Beschwerderecht bei einer
                                                Aufsichtsbehörde, insbesondere in dem Mitgliedstaat ihres gewöhnlichen Aufenthalts, ihres Arbeitsplatzes
                                                oder des Orts des mutmaßlichen Verstoßes zu. Das Beschwerderecht besteht unbeschadet anderweitiger
                                                verwaltungsrechtlicher oder gerichtlicher Rechtsbehelfe.
                                            </Paragraph>
                                        <Title level={4}>Recht auf Datenübertragbarkeit</Title>  
                                            <Paragraph>
                                                Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erfüllung eines Vertrags
                                                automatisiert verarbeiten, an sich oder an einen Dritten in einem gängigen, maschinenlesbaren Format
                                                aushändigen zu lassen. Sofern Sie die direkte Übertragung der Daten an einen anderen Verantwortlichen
                                                verlangen, erfolgt dies nur, soweit es technisch machbar ist.
                                            </Paragraph>
                                        <Title level={4}>Auskunft, Berichtigung und Löschung</Title>  
                                            <Paragraph>
                                                Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit das Recht auf unentgeltliche
                                                Auskunft über Ihre gespeicherten personenbezogenen Daten, deren Herkunft und Empfänger und den
                                                Zweck der Datenverarbeitung und ggf. ein Recht auf Berichtigung oder Löschung dieser Daten. Hierzu sowie
                                                zu weiteren Fragen zum Thema personenbezogene Daten können Sie sich jederzeit an uns wenden.
                                            </Paragraph>
                                        <Title level={4}>Recht auf Einschränkung der Verarbeitung</Title>  
                                            <Paragraph>
                                                Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
                                                Hierzu können Sie sich jederzeit an uns wenden. Das Recht auf Einschränkung der Verarbeitung besteht in
                                                folgenden Fällen:
                                            </Paragraph>
                                            <Paragraph>
                                                <ul>
                                                    <li>
                                                        Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten personenbezogenen Daten bestreiten, benötigen wir
                                                        in der Regel Zeit, um dies zu überprüfen. Für die Dauer der Prüfung haben Sie das Recht, die
                                                        Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
                                                    </li>
                                                    <li>
                                                        Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtmäßig geschah/geschieht, können Sie
                                                        statt der Löschung die Einschränkung der Datenverarbeitung verlangen.
                                                    </li>
                                                    <li>
                                                        Wenn wir Ihre personenbezogenen Daten nicht mehr benötigen, Sie sie jedoch zur Ausübung,
                                                        Verteidigung oder Geltendmachung von Rechtsansprüchen benötigen, haben Sie das Recht, statt der
                                                        Löschung die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
                                                    </li>
                                                    <li>
                                                        Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt haben, muss eine Abwägung zwischen
                                                        Ihren und unseren Interessen vorgenommen werden. Solange noch nicht feststeht, wessen Interessen
                                                        überwiegen, haben Sie das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten
                                                        zu verlangen.
                                                    </li>
                                                </ul>
                                            </Paragraph>
                                            <Paragraph>
                                                Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten eingeschränkt haben, dürfen diese Daten – von
                                                ihrer Speicherung abgesehen – nur mit Ihrer Einwilligung oder zur Geltendmachung, Ausübung oder
                                                Verteidigung von Rechtsansprüchen oder zum Schutz der Rechte einer anderen natürlichen oder
                                                juristischen Person oder aus Gründen eines wichtigen öffentlichen Interesses der Europäischen Union oder
                                                eines Mitgliedstaats verarbeitet werden.
                                            </Paragraph>
                                        <Title level={4}>SSL- bzw. TLS-Verschlüsselung</Title>  
                                            <Paragraph>
                                                Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte, wie zum
                                                Beispiel Bestellungen oder Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL- bzw. TLS Verschlüsselung. Eine verschlüsselte Verbindung erkennen Sie daran, dass die Adresszeile des Browsers von
                                                „http://“ auf „https://“ wechselt und an dem Schloss-Symbol in Ihrer Browserzeile.
                                            </Paragraph>
                                            <Paragraph>
                                                Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die Daten, die Sie an uns übermitteln, nicht
                                                von Dritten mitgelesen werden.
                                            </Paragraph>
                                    <Title level={3}>4. Plugins und Tools</Title>
                                        <Title level={4}>YouTube mit erweitertem Datenschutz</Title>  
                                            <Paragraph>
                                                Diese Website bindet Videos der Website YouTube ein. Betreiber der Website ist die Google Ireland Limited
                                                („Google”), Gordon House, Barrow Street, Dublin 4, Irland.
                                            </Paragraph>
                                            <Paragraph>
                                                Wenn Sie eine dieser Website besuchen, auf denen YouTube eingebunden ist, wird eine Verbindung zu den
                                                Servern von YouTube hergestellt. Dabei wird dem YouTube-Server mitgeteilt, welche unserer Seiten Sie
                                                besucht haben. Wenn Sie in Ihrem YouTube-Account eingeloggt sind, ermöglichen Sie YouTube, Ihr
                                                Surfverhalten direkt Ihrem persönlichen Profil zuzuordnen. Dies können Sie verhindern, indem Sie sich aus
                                                Ihrem YouTube-Account ausloggen.
                                            </Paragraph>
                                            <Paragraph>
                                                Wir nutzen YouTube im erweiterten Datenschutzmodus. Videos, die im erweiterten Datenschutzmodus
                                                abgespielt werden, werden nach Aussage von YouTube nicht zur Personalisierung des Surfens auf YouTube
                                                eingesetzt. Anzeigen, die im erweiterten Datenschutzmodus ausgespielt werden, sind ebenfalls nicht
                                                personalisiert. Im erweiterten Datenschutzmodus werden keine Cookies gesetzt. Stattdessen werden
                                                jedoch sogenannte Local Storage Elemente im Browser des Users gespeichert, die ähnlich wie Cookies
                                                personenbezogene Daten beinhalten und zur Wiedererkennung eingesetzt werden können. Details zum
                                                erweiterten Datenschutzmodus finden Sie hier:
                                                https://support.google.com/youtube/answer/171780.
                                            </Paragraph>
                                            <Paragraph>
                                                Gegebenenfalls können nach der Aktivierung eines YouTube-Videos weitere Datenverarbeitungsvorgänge
                                                ausgelöst werden, auf die wir keinen Einfluss haben.
                                            </Paragraph>
                                            <Paragraph>
                                                Die Nutzung von YouTube erfolgt im Interesse einer ansprechenden Darstellung unserer Online-Angebote.
                                                Dies stellt ein berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar. Sofern eine entsprechende
                                                Einwilligung abgefragt wurde, erfolgt die Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a
                                                DSGVO und § 25 Abs. 1 TDDDG, soweit die Einwilligung die Speicherung von Cookies oder den Zugriff auf
                                                Informationen im Endgerät des Nutzers (z. B. Device-Fingerprinting) im Sinne des TDDDG umfasst. Die
                                                Einwilligung ist jederzeit widerrufbar.
                                            </Paragraph>
                                            <Paragraph>
                                                Weitere Informationen über Datenschutz bei YouTube finden Sie in deren Datenschutzerklärung unter:
                                                https://policies.google.com/privacy?hl=de.
                                            </Paragraph>
                                            <Paragraph>
                                                Das Unternehmen verfügt über eine Zertifizierung nach dem „EU-US Data Privacy Framework“ (DPF). Der
                                                DPF ist ein Übereinkommen zwischen der Europäischen Union und den USA, der die Einhaltung
                                                europäischer Datenschutzstandards bei Datenverarbeitungen in den USA gewährleisten soll. Jedes nach
                                                dem DPF zertifizierte Unternehmen verpflichtet sich, diese Datenschutzstandards einzuhalten. Weitere
                                                Informationen hierzu erhalten Sie vom Anbieter unter folgendem Link:
                                                https://www.dataprivacyframework.gov/participant/5780.
                                            </Paragraph>
                            </div>
                        </Modal>
                    </> : <Alert 
                        message="Zugriff verweigert" 
                        description="Nutzung nur während der Öffnungsziten erlaubt."
                        type='error'
                        showIcon
                        className='error-alert'
                    />
                )
            }
            </>
        );
    }

    private addToPlaylistDynamic = async () => {
        try {
            const response = await fetch('api/Settings/CheckUser', {
                headers: { 'Content-Type': 'application/json' },
                method: 'POST',
                body: JSON.stringify({
                    'TableID': sessionStorage.getItem("table")
                })
            });
            const data = await response.json();
            this.setState({ blocked: data }, async () => {
                if (!this.state.blocked) {
                    await fetch('api/Youtube/AddToPlaylistDynamic', {
                        headers: { 'Content-Type': 'application/json' },
                        method: 'POST',
                        body: JSON.stringify({
                            VideoID: this.state.selectedRecord.videoId,
                            Title: this.state.selectedRecord.title,
                            Order: 0,
                            Table: sessionStorage.getItem("table")
                        })
                    });
                    this.setState({ modalVisible: false })
                } else {
                    message.error({
                        content: 'Zugriff verweigert!',
                        duration: 10,
                    });
                }
            })       
        } catch (error) {
            console.log('Error fetching data:', error); 
            this.setState({ blocked: true });
        }
    }

    private onSearch = async (value: string) => {
        try {
            const response = await fetch('api/Settings/CheckUser', {
                headers: { 'Content-Type': 'application/json' },
                method: 'POST',
                body: JSON.stringify({
                    'TableID': sessionStorage.getItem("table")
                })
            })
            const data = await response.json();
            this.setState({ blocked: data }, async () => {
                if (!this.state.blocked) {
                    const searchResponse = await fetch('api/Youtube/Search', {
                        headers: { 'Content-Type': 'application/json' },
                        method: 'POST',
                        body: JSON.stringify({
                            'Q': value,
                            'Part': 'snippet',
                            'MaxResults': 30,
                            'Type': 'video'
                        })
                    });
                    const searchData: SearchViewModel = await searchResponse.json();
                    this.setState({ records: Helper.ConvertData(searchData.searchResponse, SearchClass) as Array<SearchClass> });
                } else {
                    message.error({
                        content: 'Zugriff verweigert!',
                        duration: 10,
                    });
                }
            });
        } catch (error) { 
            console.log('Error fetching data:', error); 
            this.setState({ blocked: true }); 
        }
    }

    private getData = (): void => {
        fetch('api/settings/GetSettingsTimes', {
            headers: {'Content-type': 'application/json'},
            method: 'GET',
        })
        .then(response => response.json())
        .then((data: TimesViewModel) => {
            this.setState({ 
                isUserWithinTimespan: this.isUserWithinTimespan(Helper.ConvertData(data.times, TimesClass) as TimesClass),
                isLoading: false
            });
        })
    }   

    private isUserWithinTimespan(settingsTimes: TimesClass): boolean {
        const now = new Date();
        const dayOfWeek = now.getDay();
        const currentTime = now.toTimeString().split(' ')[0];
    
        let isWithinTimespan = false;
    
        if (settingsTimes.vacation)
            return false;

        switch (dayOfWeek) {
            case 0: // Sunday
                isWithinTimespan = settingsTimes.sunday && this.isTimeInRange(currentTime, settingsTimes.sundayAt, settingsTimes.sundayTo);
                break;
            case 1: // Monday
                isWithinTimespan = settingsTimes.monday && this.isTimeInRange(currentTime, settingsTimes.mondayAt, settingsTimes.mondayTo);
                break;
            case 2: // Tuesday
                isWithinTimespan = settingsTimes.tuesday && this.isTimeInRange(currentTime, settingsTimes.tuesdayAt, settingsTimes.tuesdayTo);
                break;
            case 3: // Wednesday
                isWithinTimespan = settingsTimes.wednesday && this.isTimeInRange(currentTime, settingsTimes.wednesdayAt, settingsTimes.wednesdayTo);
                break;
            case 4: // Thursday
                isWithinTimespan = settingsTimes.thursday && this.isTimeInRange(currentTime, settingsTimes.thursdayAt, settingsTimes.thursdayTo);
                break;
            case 5: // Friday
                isWithinTimespan = settingsTimes.friday && this.isTimeInRange(currentTime, settingsTimes.fridayAt, settingsTimes.fridayTo);
                break;
            case 6: // Saturday
                isWithinTimespan = settingsTimes.saturday && this.isTimeInRange(currentTime, settingsTimes.saturdayAt, settingsTimes.saturdayTo);
                break;
            default:
                break;
        }
    
        return isWithinTimespan;
    }

    private isTimeInRange(currentTime: string, startTime: string, endTime: string): boolean {
        return currentTime >= startTime || currentTime <= endTime;
    }

    TableConstruct: React.FC<any> = () => {
        const columns = [
            {
                title: "",
                dataIndex: 'thumbnailUrl',
                width: 120,
                hight: 90,
                render: (record) => <Image preview={false} src={record} />,
                onCell: (record) => { return { onClick: () => { this.setState({ selectedRecord: record}, () => { this.setState({ modalVisible: true }) })}}}
            },
            {
                title: "",
                dataIndex: 'title',
                width: 20,
                onCell: (record) => { return { onClick: () => { this.setState({ selectedRecord: record}, () => { this.setState({ modalVisible: true }) })}}}
            }
        ];

        return (
            <Table
                className="components-table-demo-nested tableStyleUsers"
                columns={columns}
                dataSource={this.state.records}
                loading={this.state.isLoading}
                rowKey={(record) => record.videoId}
                pagination={false}
                locale={{ emptyText: (<span><img src={EmptyData} /></span>) }}
            />
        );
    }
}

export const UserSettings: React.FC<{ closeModal: any }> = ({ closeModal }) => {
    const [form] = Form.useForm();
    const [smileButtonSelected, setSmileButtonSelected] = useState(false);
    const [mehButtonSelected, setMehButtonSelected] = useState(false);
    const [frownButtonSelected, setFrownButtonSelected] = useState(false);

    const resetForm = () => {
        form.resetFields();
        setSmileButtonSelected(false);
        setMehButtonSelected(false);
        setFrownButtonSelected(false);
    };


    const Submit = () => {
        const feedback = new FeedbackClass();
        feedback.name = form.getFieldValue('name');
        feedback.description = form.getFieldValue('description')
        feedback.rating = form.getFieldValue('rating');

        fetch('api/settings/AddFeedback', {
            headers: {
                'Content-type': 'application/json',
            },
            method: 'POST',
            body: JSON.stringify({
                'Feedbacks': feedback
            })
        })
        .then(() => {
            resetForm();
            closeModal();
        })
    };

    const RatingChange = (value) => {
        form.setFieldsValue({ rating: value });
    }

    return (
        <>
            <Form
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                size={"large"}
                form={form}
                onFinish={Submit}
                autoComplete="off"
                validateTrigger="onSubmit"
            >
                <Form.Item name="name">
                    <Input
                        className='removeBlueBorder'
                        placeholder="Anonym"
                        style={{ width: '70%' }}
                    />
                </Form.Item>
                <Form.Item name="description">
                    <TextArea
                        className='removeBlueBorder'
                        placeholder="Dein Feedback"
                        autoSize={{ minRows: 3, maxRows: 3 }}
                    />  
                </Form.Item>
                <Form.Item name="rating">
                    <Flex justify='space-evenly'>
                        <Button 
                            className='formButtonSize'
                            icon={<SmileOutlined style={{ fontSize: '19pt' }} />} 
                            onClick={() => {
                                setSmileButtonSelected(true);
                                setMehButtonSelected(false);
                                setFrownButtonSelected(false);
                                RatingChange(1);
                            }}
                            style={smileButtonSelected ? { backgroundColor: '#32CD32', color: 'white' } : { backgroundColor: '' }}
                        />
                        <Button 
                            className='formButtonSize'
                            icon={<MehOutlined style={{ fontSize: '19pt' }} />} 
                            onClick={() => {
                                setSmileButtonSelected(false);
                                setMehButtonSelected(true);
                                setFrownButtonSelected(false);
                                RatingChange(2);
                            }}
                            style={mehButtonSelected ? { backgroundColor: 'orange', color: 'white' } : { backgroundColor: '' }}
                        />
                        <Button 
                            className='formButtonSize'
                            icon={<FrownOutlined style={{ fontSize: '19pt' }} />} 
                            onClick={() => {
                                setSmileButtonSelected(false);
                                setMehButtonSelected(false);
                                setFrownButtonSelected(true);
                                RatingChange(3);
                            }}
                            style={frownButtonSelected ? { backgroundColor: 'red', color: 'white' } : { backgroundColor: '' }}
                        />
                    </Flex>
                </Form.Item>
                <Divider style={{ color: "#E5E5E5", borderColor: "#E5E5E5" }} />
                <Form.Item>
                    <Flex justify='end'>
                        <Button style={{ backgroundColor: 'orange', fontWeight: 'bold' }} className='removeBlueBorder' htmlType="submit">Abschicken</Button>
                    </Flex>
                </Form.Item>
            </Form>
        </>
    )
};