import * as React from 'react';

import { Button, Flex, message, Space, Table, Typography, Spin } from 'antd';
import Icon, { PauseCircleFilled, PlayCircleFilled, DeleteOutlined, SmileOutlined, MehOutlined, FrownOutlined, HistoryOutlined } from '@ant-design/icons';
import { FeedbackViewModel, TableViewModel } from '../../class/viewModel/SystemViewClass';
import { FeedbackClass, TableClass } from '../../class/SettingsClass';
import Helper from '../../classes/Helper';
import EmptyData from '../../Resources/Empty.png';
import dayjs from 'dayjs';

const { Text } = Typography;

interface State {
    isLoading: boolean,
    table: TableClass,
    tables: Array<TableClass>,
    feedback: FeedbackClass,
    feedbacks: Array<FeedbackClass>
}

export default class Tables extends React.Component<any, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            isLoading: true,
            table: new TableClass(),
            tables: [],
            feedback: new FeedbackClass(),
            feedbacks: [],
        }
    }

    componentDidMount() {
        this.getData();
    }

    public render() {
        return (
            <>
                {this.state.isLoading ? <Spin size='large' fullscreen/> : <></>}
                <Flex justify={'space-between'}>
                    <this.TableConstruct/>
                    <this.FeedbackConstruct/>
                </Flex>
            </>
        );
    }

    private getData = (): void => {
        fetch('api/settings/GetSettingsTables', {
            headers: {'Content-type': 'application/json'},
            method: 'GET',
        })
            .then(response => response.json())
            .then((data: TableViewModel) => {
                this.setState({ tables: Helper.ConvertData(data.tables, TableClass) as Array<TableClass> });
            })
            .catch(error => {
                console.error('Fetch error:', error);
                this.setState({ 
                    tables: [], 
                    isLoading: false 
                });   
            })
            .then(() => {
                fetch('api/settings/GetFeedback', {
                    headers: {'Content-type': 'application/json'},
                    method: 'GET',
            })
                .then(response => response.json())
                .then((data: FeedbackViewModel) => {
                    this.setState({ 
                        feedbacks: Helper.ConvertData(data.feedbacks, FeedbackClass) as Array<FeedbackClass>,
                        isLoading: false
                    });
                })
                .catch(error => {
                    console.error('Fetch error:', error);
                    this.setState({  
                        feedbacks: [],
                        isLoading: false  
                    });   
                });
            });
    };

    private updateTable = (record: TableClass): void => {
        record.blocked = !record.blocked;

        fetch('api/settings/UpdateTable', {
            headers: {'Content-type': 'application/json'},
            method: 'POST',
            body: JSON.stringify({
                'Tables': record
            })
        })
        .then(() => {
            this.setState(prevState => ({  
                tables: prevState.tables.map(item => 
                    item.tableID !== record.tableID
                    ? item
                    : { ...item, blocked: record.blocked, permaBlocked: record.permaBlocked }
                )
            }));
        });
    };

    private blockExpirationTable = (record: TableClass): void => {
        fetch('api/settings/BlockExpirationTable', {
            headers: {'Content-type': 'application/json'},
            method: 'POST',
            body: JSON.stringify({
                'Tables': record
            })
        })
        .then(() => {
            this.setState(prevState => ({  
                tables: prevState.tables.map(item => 
                    item.tableID !== record.tableID
                    ? item
                    : { ...item, blocked: !item.blocked, permaBlocked: !item.permaBlocked }
                )
            }));
        });
    };

    private deleteFeedback = (record: FeedbackClass): void => {
        const user: string = sessionStorage.getItem('user');
        
        if (user == 'super') {
            fetch('api/settings/DeleteFeedback', {
                headers: {'Content-type': 'application/json'},
                method: 'POST',
                body: JSON.stringify({
                    'Feedbacks': record,
                })
            })
            .then(() => { 
                const items = this.state.feedbacks.filter(item => item.id !== record.id);
                this.setState({ feedbacks: items });
            })
        } else {
            message.error({
                content: 'Zugriff verweigert! - Wir löschen keine Kommentare, außer sie sind böse',
                duration: 10,
                className: 'messageErrorDelete'
            });
        }
    }

    TableConstruct: React.FC<any> = () => {
        const columns = [
            {
                title: "Tisch",
                dataIndex: 'tableID',
                align: 'center' as const,
            },
            {
                title: "Aktionen", 
                align: 'center' as const, 
                render: (record: TableClass) => {
                    return (
                        <Space>
                            {record.permaBlocked ? 
                                <Button onClick={() => { this.updateTable(record), record.permaBlocked = !record.permaBlocked }} >
                                    <HistoryOutlined style={{ fontSize: '18pt' }}/>
                                </Button>
                            :
                            <>
                                {!record.blocked ? 
                                    <>
                                        <Button onClick={() => { this.updateTable(record) }} >
                                            {record.blocked ? <PlayCircleFilled style={{ fontSize: '19pt', color: 'orange' }}/> : <PauseCircleFilled style={{ fontSize: '19pt', color: 'orange' }}/>}
                                        </Button>
                                        <Button type='dashed' onClick={() => { this.blockExpirationTable(record) }} >
                                            <Text>Zeitliche Sperre</Text>
                                        </Button>
                                    </>
                                    :
                                    <>
                                        <Button onClick={() => { this.updateTable(record) }} >
                                            <PlayCircleFilled style={{ fontSize: '19pt', color: 'red' }}/>
                                        </Button>
                                    </>
                                }
                            </>}
                        </Space>
                    )
                }
            },
        ];

        return (
            <Table
                className="components-table-demo-nested tableStyle"
                columns={columns}
                dataSource={this.state.tables}
                pagination={false}
                rowKey={(record) => record.tableID}
                locale={{ emptyText: (<span><img src={EmptyData} /></span>) }}
            />
        );
    }

    FeedbackConstruct: React.FC<any> = () => {
        const columns = [
            {
                title: "",
                width: 50,
                sorter: (a, b) => a.rating - b.rating,
                render: (record: FeedbackClass) => {
                    return (
                        <>
                            {record.rating == 1 ? 
                                <SmileOutlined style={{ fontSize: '22pt', color: '#32CD32' }} /> :
                       
                                <>
                                    {record.rating == 2 ?
                                        <MehOutlined style={{ fontSize: '22pt', color: 'orange' }} /> :
                                        <FrownOutlined style={{ fontSize: '22pt', color: 'red' }} />
                                    }
                                </>
                            }
                        </>
                    )
                }
            },
            {
                title: "Feedback",
                dataIndex: 'description',
                width: 550
            },
            {
                title: "Von",
                dataIndex: 'name',
                width: 200
            },
            {
                title: "Erstellt am",
                align: 'center' as const,
                width: 130,
                render: (record: FeedbackClass) => {
                    return (
                        <Text>{dayjs(record.created, 'DD-MM-YYYY HH:mm').format('DD.MM.YYYY HH:mm')}</Text>
                    )
                }
            },
            {
                align: 'center' as const, 
                width: 10,
                render: (record: FeedbackClass) => {
                    return (
                        <Button
                            danger
                            icon={<DeleteOutlined />}
                            onClick={() => { this.deleteFeedback(record) }}
                        />
                    )
                }
            },
        ];

        return (
            <Table
                className="components-table-demo-nested tableStyleFeedback"
                columns={columns}
                dataSource={this.state.feedbacks}
                pagination={{ pageSize: 5 }}
                rowKey={(record) => record.id}
                locale={{ emptyText: (<span><img src={EmptyData} /></span>) }}
            />
        );
    }
}