export const allowedTables: Record<string, string> = {
  "7d9f3e4b-6d18-4f31-a9e7-4d3b2d85c3f7": "1",
  "b2a97439-4f84-4d9a-8d71-2f4f5b8f8d63": "2",
  "28f6dcb6-27f6-4b8e-9f83-d5b5f4b1a83a": "3",
  "d4f9e3a5-6e5b-42d8-a5e4-3c8f5e2b3d96": "4",
  "9c3f7a2e-4d6f-4f71-8e7b-3d5b2e4f8a7f": "5",
  "f4b6e8c3-5a1e-4c6d-8d7f-2b3a9e8f7d1b": "6",
  "7b1f2c8d-4d5e-4e6a-8f9c-3d2a6e7f5b4e": "7",
  "8a4b7f9e-3d6c-4f1e-8e9f-5d7a3b2e4c6f": "8",
  "c8d9e3b7-6f4e-4d7a-9c3f-2b8a7e4d5f6e": "9",
  "a9e3b7f2-4d5c-4e8f-3d9a-5e7b2c4f6a8d": "10",
  "3f6a7b2e-8d4c-4f1e-9e7b-2d3a6b8f4c5e": "11",
  "e2b3f6d9-7a1e-4c8d-5f6b-4d2a7e9f3b8c": "12"
};

export const allowedAdmin: Record<string, string> = {
  "550e8400-e29b-41d4-a716-446655440000": "super",
  "123e4567-e89b-12d3-a456-426614174000": "admin"
};