export class SearchClass {
    public videoId: string;
    public thumbnailUrl: string;
    public title: string;

    constructor() {
        this.videoId = '';
        this.thumbnailUrl = '';
        this.title = '';
    }
}