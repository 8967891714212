declare global {
  interface Window {
    onYouTubeIframeAPIReady: () => void;
    YT: any;
  }
}

import React, { useEffect, useRef } from 'react';

interface PlayerFrameProps {
  videoId: string;
  width: string;
  height: string;
  onStateChange?: () => void;
}

const PlayerFrame: React.FC<PlayerFrameProps> = ({ videoId, width, height, onStateChange }) => {
  const playerRef = useRef(null);

  useEffect(() => {
    const loadYouTubeAPI = () => {
      if (!document.getElementById('youtube-api')) {
        const tag = document.createElement('script');
        tag.id = 'youtube-api';
        tag.src = "https://www.youtube.com/iframe_api";
        tag.dataset.name = "youtube-api";
        const firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
      }
    };

    loadYouTubeAPI();
    
    window.onYouTubeIframeAPIReady = () => {
      playerRef.current = new window.YT.Player('player', {
        height: height,
        width: width,
        videoId: videoId,
        events: {
          'onReady': onPlayerReady,
          'onStateChange': onPlayerStateChange
        }
      });
    };

    const onPlayerReady = (event: any) => {
      event.target.playVideo();
    };

    const onPlayerStateChange = (event: any) => {
      if (event.data === window.YT.PlayerState.ENDED) {
        if (onStateChange) {
          onStateChange();
        }
      }
    };

    if (
      window.YT &&
      document.querySelector('script[data-name="youtube-api"]')
    ) {
      playerRef.current = new window.YT.Player("player", {
        height: height,
        width: width,
        videoId: videoId,
        events: {
          onReady: onPlayerReady,
          'onStateChange': onPlayerStateChange
        },
      });
    }

    return () => {
      if (playerRef.current) {
        playerRef.current.destroy();
      }
    };
  }, []);

  useEffect(() => {
    if (playerRef.current && playerRef.current.loadVideoById) {
      playerRef.current.loadVideoById(videoId);
    }
  }, [videoId]);

  return (
    <div>
      <div id="player"></div>
    </div>
  );
};

export default PlayerFrame;
