import { Component } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import 'antd/dist/reset.css';
import '../src/css/CustomStyle.scss';

import Admin from './components/Admin/Admin';
import Users from './components/Users';
import Error from './components/Error';
import ErrorBoundary from './ErrorBoundary';
import CheckTable from './CheckTable';
import CheckAdmin from './CheckAdmin';

export default class App extends Component<any, any> {
    render() {
        return (
            <Router>
                <ErrorBoundary>
                    <Routes>
                        <Route 
                            path='/Interface' 
                            element={
                                <CheckTable>
                                    <Users/>
                                </CheckTable>
                            } 
                        />
                        <Route 
                            path='/Admin' 
                            element={
                                <CheckAdmin>
                                    <Admin/>
                                </CheckAdmin>
                            } 
                        />
                        <Route path='/Error' element={<Error />} />
                        <Route path="*" element={<Error />} />
                    </Routes>
                </ErrorBoundary>
            </Router>
        );
    }
}